"use client";
import { useEffect, useState, useRef, useCallback } from "react";
import TitleWrapper from "./TitleWrapper";
import Image from "next/image";
import Link from "next/link";
import { motion } from "framer-motion";
import CategoryLinks from "./CategoryLinks";
import { FaExclamationCircle } from "react-icons/fa";
import { FaCirclePlus } from "react-icons/fa6";
import { useParams } from "next/navigation";

const Posts = ({ postsData, blogData, cat, showSinglePost }) => {
  const [imageUrls, setImageUrls] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [category, setCategory] = useState(null);
  const [page, setPage] = useState(postsData.page);
  const [posts, setPosts] = useState(postsData.posts);

  const [hasMore, setHasMore] = useState(postsData.page < postsData.totalPages);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const isInitialMount = useRef(true);
  const { slug } = useParams();
  const showSinglePostCallback = useCallback(() => {
    showSinglePost(true);
  }, [showSinglePost]);

  useEffect(() => {
    setPage(1);
    if (slug && (category !== null || searchTerm !== "")) {
      showSinglePostCallback();
    }
  }, [slug, category, searchTerm, showSinglePostCallback]);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }

    const fetchData = async () => {
      const isNewSearch = page === 1;
      try {
        if (isNewSearch) setIsLoading(true);
        else setLoadingMore(true);
        const postLimit = slug ? 6 : 8;
        const queryParams = new URLSearchParams({
          page,
          limit: postLimit,
          ...(searchTerm && { search: searchTerm }),
          ...(category !== null && { category_id: category }),
        });

        const res = await fetch(
          `${process.env.NEXT_PUBLIC_POST_URL}/api/posts/site/${process.env.NEXT_PUBLIC_SITE_ID}?${queryParams}`
        );

        if (!res.ok) throw new Error("Failed to fetch posts");
        const data = await res.json();

        setPosts((prev) => {
          if (page === 1) return data.posts;
          const merged = [...prev, ...data.posts];
          return merged.filter(
            (p, i, arr) =>
              arr.findIndex((item) => item.post_id === p.post_id) === i
          );
        });

        setHasMore(data.page < data.totalPages);
      } catch (error) {
        console.error("Fetch error:", error);
        setHasMore(false);
      } finally {
        if (isNewSearch) setIsLoading(false);
        else setLoadingMore(false);
      }
    };

    fetchData();
  }, [slug, page, searchTerm, category]);

  useEffect(() => {
    const extractImages = () => {
      const urls = {};
      posts.forEach((post) => {
        const div = document.createElement("div");
        div.innerHTML = post.post_desc;
        const img = div.querySelector("img");
        if (img) {
          urls[post.post_id] = img.src.replace(
            `${process.env.NEXT_PUBLIC_OWN_URL}api/uploads`,
            `${process.env.NEXT_PUBLIC_CMS_URL}api/uploads`
          );
        }
      });
      setImageUrls(urls);
    };

    extractImages();
  }, [posts]);

  const handleLoadMore = () => {
    if (hasMore && !loadingMore) setPage((p) => p + 1);
  };

  return (
    <>
      <div className="category_wrapper mt-8">
        <h2 className="text-[#00359f] text-xl text-center">
          {blogData?.subtitle_text}
        </h2>
        <CategoryLinks
          data={cat}
          setCategory={setCategory}
          setSearchTerm={setSearchTerm}
        />
      </div>

      <div className="search_wrapper flex gap-5 items-center justify-center my-6">
        <input
          type="search"
          className="bg-[#c8d3de] text-[#00359f] outline-0 px-4 py-2 font-bold"
          placeholder="Inserisci una parola chiave"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <button className="font-bold border-0 bg-[#f5f6f7] py-2 px-3">
          Cercare
        </button>
      </div>

      {!slug || category !== null || searchTerm !== "" ? (
        <>
          {isLoading && <></>}
          {!isLoading && posts.length === 0 && (
            <p className="font-bold mt-4 flex gap-2 text-gray-400 text-lg justify-center items-center">
              <FaExclamationCircle className="text-[#2b37ce]" />
              <span>Nessun risultato trovato. Prova un&apos;altra parola</span>
            </p>
          )}

          {!isLoading && posts.length > 0 && (
            <>
              <div
                className={`post_wrapper gap-3 grid grid-cols-1 md:grid-cols-2  ${
                  slug ? "lg:grid-cols-3" : "lg:grid-cols-4"
                }`}
              >
                {posts.map((post) => (
                  <motion.div
                    key={post.post_id}
                    className="relative group overflow-hidden"
                    initial={{ opacity: 0, rotateX: -90 }}
                    whileInView={{ opacity: 1, rotateX: 0 }}
                    viewport={{ once: true, amount: 0.2 }}
                    transition={{ duration: 0.7, ease: "easeOut" }}
                  >
                    <div className="relative w-full h-[260px] lg:w-[280px] lg:h-[260px]">
                      {imageUrls[post.post_id] ? (
                        <Image
                          src={imageUrls[post.post_id]}
                          alt={post.post_title}
                          fill
                          className="object-cover"
                          sizes="(max-width: 1024px) 100vw, 260px"
                          priority={false}
                        />
                      ) : (
                        <div className="bg-gray-300 w-full h-full" />
                      )}
                    </div>
                    <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 bg-black/40 transition-opacity duration-300">
                      <Link
                        href={post.post_slug}
                        className="text-white text-2xl text-center p-4"
                      >
                        <h4>{post.post_title}</h4>
                      </Link>
                    </div>
                  </motion.div>
                ))}
              </div>

              {hasMore && (
                <div className="text-center mt-6">
                  <button
                    onClick={handleLoadMore}
                    disabled={loadingMore}
                    className="bg-[#00359f] text-white px-4 py-2 rounded-lg text-sm hover:bg-[#ffd966] transition-colors duration-75 flex items-center gap-2 mx-auto cursor-pointer"
                  >
                    <FaCirclePlus className="text-[#ffd966]" />
                    {loadingMore ? "Caricamento..." : "Vedi altre voci"}
                  </button>
                </div>
              )}
            </>
          )}
        </>
      ) : null}
    </>
  );
};

export default Posts;

import React from "react";

const CategoryLinks = ({ data, setCategory, setSearchTerm }) => {
  const handleCategoryClick = (cat_id) => {
    setCategory(cat_id);
    setSearchTerm("");
  };
  return (
    <div className="my-4 text-center text-sm">
      {data.map((cat) => (
        <button
          className="rounded m-2 cursor-pointer"
          key={cat.cat_id}
          onClick={() => handleCategoryClick(cat.cat_id)}
        >
          <span className="bg-[#c8d3de] hover:bg-[#ffd966] duration-200 px-3 py-1 text-[#00359f]">
            {cat.cat_name}
          </span>
          <span className="bg-[#00359f] px-2 py-1 text-white">
            ({cat.post_count})
          </span>
        </button>
      ))}
    </div>
  );
};

export default CategoryLinks;

const TitleWrapper = ({ title, text }) => {
  const renderText = (text) => {
    if (!text) return null;
    return text.split(/<br\s*\/?>|\n/).map((line, index) => (
      <p
        key={index}
        className="text-[#575757] font-light text-sm"
      >
        {line}
      </p>
    ));
  };

  return (
    <div className="text-center">
      <h2 className="text-3xl lg:text-4xl font-normal mb-2">{title}</h2>
      <div className="text-[#575757] font-light text-sm">
        {renderText(text)}
      </div>
    </div>
  );
};

export default TitleWrapper;

"use client";
import Image from "next/image";
import Link from "next/link";
import { FaArrowCircleLeft } from "react-icons/fa";
import IconRenderer from "../utils/libs/IconRenderer";
import Posts from "./Posts";
import { useState } from "react";

const SinglePostContent = ({
  setting,
  data,
  iconPost,
  blogData,
  cat,
  postData,
}) => {
  const processedDesc = data.currentPost.post_desc.replace(
    /<img\s+[^>]*src="(\/[^"]*)"/g,
    `<img src="${process.env.NEXT_PUBLIC_CMS_URL}$1"`
  );
  const extractImage = (html) => {
    const imgRegex = /<img[^>]+src="([^">]+)"/;
    const match = html.match(imgRegex);
    if (match && match[1]) {
      const src = match[1];
      const updatedSrc = src.replace(
        `${process.env.NEXT_PUBLIC_OWN_URL}api/uploads`,
        `${process.env.NEXT_PUBLIC_CMS_URL}api/uploads`
      );
      return updatedSrc;
    }
    return null;
  };
  const { currentPost, relatedPosts, previousPost, nextPost } = data;
  const postUrl = `${process.env.NEXT_PUBLIC_OWN_URL}/${currentPost.post_slug}`;

  const [showPosts, setShowPosts] = useState(false);

  return (
    <>
      <Posts
        blogData={blogData}
        postsData={postData}
        cat={cat}
        showSinglePost={setShowPosts}
      />
      {!showPosts && (
        <>
          <div className="single_post_wrapper">
            <div className="title_wrappe">
              <h1 className="text-center text-4xl text-black my-6">
                {currentPost.post_title}
              </h1>
              <div
                className="post_desc_wrapper"
                dangerouslySetInnerHTML={{ __html: processedDesc }}
              />
            </div>
            <h2 className="text-center mt-3">
              <span className="border-b text-black text-sm font-bold border-black pb-2 pt-6">
                {setting[0]?.share_text}
              </span>
            </h2>
            <div className="social_icons_wrapper flex items-center justify-center lg:justify-end gap-3 mt-5 lg:mt-0">
              {iconPost?.map((icon, index) => {
                const isLast = index === iconPost.length - 1;
                return (
                  <Link
                    key={icon.sh_id}
                    href={`${icon.icon_link}${encodeURIComponent(postUrl)}`}
                    target="_blank"
                    className={`text-2xl bg-black text-white p-1 rounded-sm ${
                      isLast ? "whatsapp_icon_social" : ""
                    }`}
                    rel="noopener noreferrer"
                  >
                    <IconRenderer
                      iconName={icon.icon_name}
                      size={16}
                    />
                  </Link>
                );
              })}
            </div>
            <div className="relative_category_wrapper gap-10 my-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
              {relatedPosts.length > 0 &&
                relatedPosts.map((postCat, index) => {
                  return (
                    <Link
                      key={index}
                      href={`/${postCat.post_slug}`}
                      className="text-sm text-black text-center"
                    >
                      <span className="relative block w-full h-[150px] lg:w-[240px] lg:h-[150px] ">
                        <Image
                          src={extractImage(postCat.post_desc)}
                          alt="Category post"
                          fill
                          className="object-cover"
                          sizes="(max-width: 1024px) 100vw, 265px"
                          priority={false}
                        />
                      </span>
                      <span className="mt-3">{postCat.post_title}</span>
                    </Link>
                  );
                })}
            </div>
          </div>
          <div className="pagination_wrapper flex items-center justify-center gap-3 py-8">
            {nextPost && (
              <Link
                href={`/${nextPost.post_slug}`}
                className="bg-[#00359f] text-white px-4 py-2 rounded-lg text-sm hover:bg-[#ffd966] duration-75 flex items-center gap-2"
              >
                <FaArrowCircleLeft className="text-[#ffd966] whatsapp_icon_social" />{" "}
                {setting[0]?.previous_post_button_text}
              </Link>
            )}
            {previousPost && (
              <Link
                href={`/${previousPost.post_slug}`}
                className="bg-[#00359f] text-white px-4 py-2 rounded-lg text-sm hover:bg-[#ffd966] duration-75"
              >
                {setting[0]?.next_post_button_text}
              </Link>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default SinglePostContent;
